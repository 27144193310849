@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;
@forward "theme";
@use "theme" as t;
@forward "./material-icons";
@include mat.elevation-classes();
@include mat.app-background();


$cvx-primary: mat.m2-define-palette(t.$mat-chevron-blue, 500);
$cvx-accent: mat.m2-define-palette(t.$mat-chevron-green, 500, 200);
$cvx-warn: mat.m2-define-palette(t.$mat-chevron-red, 500, 200);
$cvx-typography: mat.m2-define-typography-config(
  $font-family: '"GothamBook", Arial, sans-serif',
);

$cvx-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $cvx-primary,
      accent: $cvx-accent,
      warn: $cvx-warn,
    ),
    typography: $cvx-typography,
    density: 0,
  )
);

@include mat.elevation-classes();
@include mat.app-background();
@include mat.progress-spinner-theme($cvx-theme);
@include mat.all-component-themes($cvx-theme);
@include mtx.all-component-themes($cvx-theme);

main.container {
  display: block;
  position: relative;

  mat-card {
    margin-bottom: 15px;
  }

  mat-card-content > * {
    padding: 10px;
  }

  mat-card-content {
    mat-spinner {
      padding: 0px;
    }
  }

  mat-form-field {
    width: 100%;
  }
}

mat-chip {
  mat-icon {
    vertical-align: middle;
  }
  background-color: t.$light-gray;
}

label {
  margin-bottom: 0 !important; // overriding NextPage because it is messing up with alignment of elements
}
