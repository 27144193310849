@use 'sass:color';

//May need to be updated depending on the chosen color scheme for the site.
$white: #ffffff;
$black: #000000;

$dark-blue: #0b2d71;
$medium-blue: #0066b2;
$light-blue: #009dd9;

$dark-teal: #003653;
$medium-teal: #00708c;
$light-teal: #00b3bd;

$dark-green: #444b0d;
$medium-green: #769231;
$light-green: #b2cc34;

$dark-orange: #711b00;
$medium-orange: #e5601f;
$light-orange: #faab18;

$dark-red: #58001c;
$medium-red: #96002e;
$light-red: #e21836;

$dark-purple: #3a0d36;
$medium-purple: #751269;
$light-purple: #ba3093;

$dark-gray: #6b6d6f;
$medium-gray: #8c8f93;
$light-gray: #dbdcdd;
$background-gray: #f0f0f0;

$mat-chevron-blue: (
  100: $light-blue,
  200: $light-blue,
  300: $light-blue,
  400: $medium-blue,
  500: $medium-blue,
  600: $medium-blue,
  700: $dark-blue,
  800: $dark-blue,
  900: $dark-blue,
  contrast: (
    100: $dark-blue,
    200: $dark-blue,
    300: $dark-blue,
    400: $black,
    500: $white,
    600: $black,
    700: $white,
    800: $white,
    900: $white,
  ),
);

$mat-chevron-purple: (
  100: $light-purple,
  200: $light-purple,
  300: $light-purple,
  400: $medium-purple,
  500: $medium-purple,
  600: $medium-purple,
  700: color.adjust($dark-purple, $lightness: 8%),
  800: color.adjust($dark-purple, $lightness: 8%),
  900: color.adjust($dark-purple, $lightness: 8%),
  contrast: (
    100: color.adjust($dark-purple, $lightness: 8%),
    200: color.adjust($dark-purple, $lightness: 8%),
    300: color.adjust($dark-purple, $lightness: 8%),
    400: $black,
    500: $black,
    600: $black,
    700: $white,
    800: $white,
    900: $white,
  ),
);

$mat-chevron-green: (
  100: $light-green,
  200: $light-green,
  300: $light-green,
  400: $medium-green,
  500: $medium-green,
  600: $medium-green,
  700: $dark-green,
  800: $dark-green,
  900: $dark-green,
  contrast: (
    100: $dark-green,
    200: $dark-green,
    300: $dark-green,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  ),
);

$mat-chevron-red: (
  100: $light-red,
  200: $light-red,
  300: $light-red,
  400: $medium-red,
  500: $medium-red,
  600: $medium-red,
  700: $dark-red,
  800: $dark-red,
  900: $dark-red,
  contrast: (
    100: $dark-red,
    200: $dark-red,
    300: $dark-red,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  ),
);
