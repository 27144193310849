// Material Icons
// Hosted Locally
// Icons source: https://github.com/google/material-design-icons -> https://github.com/marella/material-icons#readme

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/assets/icons/material-icons.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
