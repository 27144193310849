/* You can add global styles to this file, and also import other style files */
@use "styles/main.scss" as m;

.success-snackbar {
  color: m.$white;
  background-color: m.$medium-green;
  --mdc-snackbar-container-color: m.$medium-green;
  --mat-snack-bar-button-color: m.$white;
}

.error-snackbar {
  color: m.$white;
  background-color: m.$medium-red;
  --mdc-snackbar-container-color: m.$medium-red;
  --mat-snack-bar-button-color: m.$white;
}
